<template>
  <v-btn
    to="/"
    icon
    active-class="no-active"
  >
    <v-icon>mdi-home</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'HomeButton',
};
</script>

<style scoped>
/* Needed for Home Button without active class */
.v-btn--active.no-active:not(:focus):not(:hover)::before {
  opacity: 0 !important;
}
</style>
